// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/HelloWorld',
        name: 'HelloWorld',
        component: () => import('@/components/HelloWorld.vue')
    },
    {
        path: '/',
        name: 'AiCustomized',
        component: () => import('@/components/AIdingzhi/AiCustomized.vue')
    },
    {
        path: '/PptMakeHeader',
        name: 'PptMakeHeader',
        component: () => import('@/components/Make/PptMakeHeader.vue')
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
