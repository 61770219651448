import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';

const app = createApp(App); // 创建 Vue 应用实例
app.use(ElementPlus);
app.use(router);
app.mount('#app');
// 注册路由
